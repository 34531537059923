$(document).ready(function() {
	if (screen && screen.width > 1199) {
		$('#fullpage').fullpage({
			//scrollOverflow: true,
			responsiveWidth: 1024,
		});
		$.mCustomScrollbar.defaults.scrollButtons.enable=true; //enable scrolling buttons by default
		$('.list-project').mCustomScrollbar({
			//theme:"dark"
		});
		
		$('.content-detail').mCustomScrollbar({
			//theme:"dark"
		});
	}
	
	// $('.content-project').mCustomScrollbar({
	// 	//theme:"dark"
	// });
	$('.nav-slide').slick({
		dots: false,
		infinite: false,
		slidesToShow: 5,
		slidesToScroll: 5,
	   // / asNavFor: '.product-slide',
		focusOnSelect: true,
		prevArrow: $('.prev'),
		nextArrow: $('.next'),
		vertical: true,
		verticalSwiping: true,
		responsive: [{
				breakpoint: 1199,
				settings: {
					slidesToShow: 4,
					vertical: false,
					verticalSwiping: false,
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 3,
					vertical: false,
					verticalSwiping: false,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 5,
					vertical: false,
					verticalSwiping: false,
				}
			},
			{
				breakpoint: 543,
				settings: {
					slidesToShow: 3,
					vertical: false,
					verticalSwiping: false,
				}
			}
		],
	})
	$('.list-thumnail .item').click(function(){
		var data = $(this).attr('data-item');
		$('.list-thumnail .item').removeClass('active-item');
		$(this).addClass('active-item');
		$('.slide-pc .item').each(function(){
			if(data == $(this).attr('data-view'))
			{
				$('.slide-pc .item').removeClass('active');
				$(this).addClass('active');
			}
		});
		
	})
	$('.prev').click(function(){
		var data = $('.slick-current').attr('data-item');
		$('.slide-pc .item').each(function(){
			if(data == $(this).attr('data-view'))
			{
				$('.slide-pc .item').removeClass('active');
				$(this).addClass('active');
			}
		});
	});
	$('.next').click(function(){
		var data = $('.slick-current').attr('data-item');
		$('.slide-pc .item').each(function(){
			if(data == $(this).attr('data-view'))
			{
				$('.slide-pc .item').removeClass('active');
				$(this).addClass('active');
			}
		});
	});
	$('.show-menu').click(function(){
		$('.menu-page').slideToggle();
	});
	var wid = $('.small').width();
	$('.custom-padding').height(wid);
	var thum = $('.projectdetail-page .list-thumnail .item img').width();
	$('.projectdetail-page .list-thumnail .item img').height(thum);
	var rh = $('.group-arows').height();
	$('.group-arows .arows').height(rh);
});

$(document).ready(function() {
	$('.slick-active').first().addClass('active-item');
	var n = $('.list-thumnail .item').length -1;
	var k = 0;
	var next = $('.slick-active[data-slick-index="3"]').delay(18000).offset().top;
	var prev = $('.slick-active[data-slick-index="0"]').delay(18000).offset().top;
	console.log(n);
	console.log(next);
	console.log(prev);
	$('.img-next').click(function(){
		$('.slick-active').each(function(){

			if($(this).hasClass('active-item'))
			{
				console.log($(this).index());
				if($(this).index() < n)
				{
					console.log($(this).offset().top);
					if($(this).offset().top > next)
					{
						$('.next').trigger('click');
						$('.slick-active').first().addClass('active-item');

					}
					else{
						var ab = $(this).index();
						$(this).removeClass('active-item');
						$('.list-thumnail .item').eq(ab + 1).addClass('active-item');
						var data = $('.active-item').attr('data-item');
						$('.slide-pc .item').each(function(){
							if(data == $(this).attr('data-view'))
							{
								$('.slide-pc .item').removeClass('active');
								$(this).addClass('active');
							}
						});
						return false;
					}
				}
			}
				
		});
	});
	$('.img-prev').click(function(){
		$('.slick-active').each(function(){
			if($(this).hasClass('active-item'))
			{
				console.log($(this).index());
				if($(this).index() >= 1)
				{
					console.log($(this).offset().top);
					if($(this).offset().top < prev)
					{
						$('.prev').trigger('click');
						$('.slick-active').last().addClass('active-item');

					}
					else{
						var ab = $(this).index();
						$(this).removeClass('active-item');
						$('.list-thumnail .item').eq(ab - 1).addClass('active-item');
						var data = $('.active-item').attr('data-item');
						$('.slide-pc .item').each(function(){
							if(data == $(this).attr('data-view'))
							{
								$('.slide-pc .item').removeClass('active');
								$(this).addClass('active');
							}
						});
						return false;
					}
				}
			}
				
		})
	});
	$('.back-page').click(function(){
		//alert('asdasd');
		window.history.back();
	});
	    
})

$(document).ready(function() {
	var url = window.location.href.substr(window.location.href.lastIndexOf("/"));
	console.log(url);
	$(".menu-page ul li a").each(function(){
		  if($(this).attr("href") == url || $(this).attr("href") == '' )
		  $(this).parent().addClass("active");
	});
})